import Vue from "vue";
import VueRouter from "vue-router";
import { MessageBox, Message } from "element-ui"
Vue.use(VueRouter)
export const routes = [
    {
        path: "*",
        redirect: "/layout",
        hidden: true,
    }, {
        path: '/layout',
        names: "layout",
        component: () => import("../components/layout/index.vue"),
        children: [
            //重定向
            {
                path: "",
                redirect: '/layout/Dashboard',
                hidden: true,
            },
            {
                path: "/layout/Dashboard",
                names: "Dashboard",
                component: () => import("../view/Dashboard/index.vue"),
                meta: {
                    title: 'Dashboard',
                    name: "Dashboard",
                    apiActiveMenu: '/layout/Dashboard',
                    icon: "dashboard",
                },
                children: [
                    {
                        path: "/layout/Dashboard/DataUsage",
                        name: "DataUsage",
                        meta: {
                            title: 'Usage Report',
                            apiActiveMenu: '/layout/Dashboard', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Dashboard', 'Report']   //面包屑导航
                        },
                        component: () => import("../view/Dashboard/DashReport/index.vue")
                    }, {
                        path: "/layout/Dashboard/UsageDetails",
                        name: "MonthDetail",
                        meta: {
                            title: 'Usage Details',
                            apiActiveMenu: '/layout/Dashboard', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Dashboard', 'Report']   //面包屑导航
                        },
                        component: () => import("../view/Dashboard/UsageDetails/index.vue")
                    },
                    {
                        path: "/layout/Dashboard/BillDetail",
                        name: "BillDetail",
                        meta: {
                            title: 'Bills',
                            apiActiveMenu: '/layout/Dashboard', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Dashboard', 'Billing']   //面包屑导航
                        },
                        component: () => import("../view/Dashboard/BillDetail/index.vue")
                    },
                    {
                        path: "/layout/Dashboard/Balance",
                        name: "PrepareDetail",
                        meta: {
                            title: 'Balance',
                            apiActiveMenu: '/layout/Dashboard', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Dashboard', 'Billing']   //面包屑导航
                        },
                        component: () => import("../view/Dashboard/Balance/index.vue")
                    },
                ]
            },
            {
                path: "/layout/Identity",
                names: "Identity",
                component: () => import("../view/Identity/index.vue"),
                meta: {
                    title: 'Identity',
                    icon: "figureicon",
                    apiActiveMenu: '/layout/Identity'//高亮
                },
                children: [
                    // 信息核验 实名 ktp核验
                    {
                        path: "/layout/Identity/NameVerifyKtp",
                        name: "NameVerifyKtp",
                        meta: {
                            title: 'Indentity Check',
                            apiActiveMenu: '/layout/Identity', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Verification']   //面包屑导航
                        },
                        component: () => import("../view/Identity/KtpVerification/index.vue")
                    },
                    // 信息核验手机号核验
                    {
                        path: "/layout/Identity/PhoneNo",
                        name: "PhoneNo",
                        component: () => import("../view/Identity/PhoneNumber/index.vue"),
                        meta: {
                            title: 'Phone Number Verfication',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Verification']  //面包屑导航
                        },
                    },//BioVerify
                    {
                        path: "/layout/Identity/BioVerify",
                        name: "BioVerify",
                        component: () => import("../view/Identity/BioVerify/index.vue"),
                        meta: {
                            title: 'Biometric Verification',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Verification']  //面包屑导航
                        },
                    }, {
                        path: "/layout/Identity/BankIdentity",
                        name: "BankIdentity",
                        component: () => import("../view/Identity/BankIdentity/index.vue"),
                        meta: {
                            title: 'Indentity Verification',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Verification']  //面包屑导航
                        },
                    },
                    // 信息核验 人脸对比
                    {
                        path: "/layout/Identity/PhotoCompare",
                        name: "PhotoCompare",
                        component: () => import("../view/Identity/PhotoCompare/index.vue"),
                        meta: {
                            title: 'Face Comparison',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Face Recognition']  //面包屑导航
                        },
                    },
                    //信息核验 手持身份证
                    {
                        path: "/layout/Identity/KtpHold",
                        name: "KtpHold",
                        component: () => import("../view/Identity/KtpHolding/index.vue"),
                        meta: {
                            title: 'Hand-held KTP',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Face Recognition']  //面包屑导航
                        },
                    },
                    //人脸查询
                    {
                        path: "/layout/Identity/FaceSearch",
                        name: "FaceSearch",
                        component: () => import("../view/Identity/Facesearch/index.vue"),
                        meta: {
                            title: 'Face Search',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Face Recognition']  //面包屑导航
                        },
                    },
                    {
                        path: "/layout/Identity/RiskFace",
                        name: "RiskFace",
                        component: () => import("../view/Identity/RiskFace/index.vue"),
                        meta: {
                            title: 'Risk Face',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Face Recognition']  //面包屑导航
                        },
                    },
                    {
                        path: "/layout/Identity/KtpOcr",
                        name: "KtpOcr",
                        component: () => import("../view/Identity/KtpPhoto/index.vue"),
                        meta: {
                            title: 'OCR KTP',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'OCR']  //面包屑导航
                        },
                    },
                    {
                        path: "/layout/Identity/NpwpOcr",
                        name: "NpwpOcr",
                        component: () => import("../view/Identity/NpwpPhoto/index.vue"),
                        meta: {
                            title: 'OCR NPWP',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'OCR']  //面包屑导航
                        },
                    }, {
                        path: '/layout/Identity/IdAntiFraud',
                        name: "IdAntiFraud",
                        component: () => import("../view/Identity/IdAntiFraud/index.vue"),
                        meta: {
                            title: 'KTP Photo Image Anti-Fraud',
                            apiActiveMenu: '/layout/Identity',// 主菜单 的 接口文档 高亮
                            breadcrumb: ['Identity', 'Image anti-fraud']  //面包屑导航
                        },
                    }

                ]
            }, {
                path: "/layout/Liveness",
                names: "Liveness",
                component: () => import("../view/Liveness/index.vue"),
                meta: {
                    title: 'Liveness',
                    apiActiveMenu: '/layout/Liveness',
                    icon: "faceicon",
                },
                children: [
                    {
                        path: "/layout/Liveness/SdkIntegration",
                        name: "SdkIntegration",
                        meta: {
                            title: 'SDK Integration',
                            apiActiveMenu: '/layout/Liveness', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Liveness', 'Liveness Dection']   //面包屑导航
                        },
                        component: () => import("../view/Liveness/SdkIntegration/index.vue")
                    }, {
                        path: "/layout/Liveness/ApiUsage",
                        name: "ApiUsage",
                        meta: {

                            title: 'Picture Live',
                            apiActiveMenu: '/layout/Liveness', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Liveness', 'Liveness Dection']   //面包屑导航
                        },
                        component: () => import("../view/Liveness/ApiUsage/index.vue")
                    },
                ]
            },
            {
                path: "/layout/Financials",
                names: "Financials",
                component: () => import("../view/Financials/index.vue"),
                meta: {
                    title: 'Report',
                    apiActiveMenu: '/layout/Financials',//高亮
                    icon: "bookicon",
                },
                children: [
                    {
                        path: "/layout/Financials/PhoneFintechAppV2",
                        name: "PhoneFintechAppV2",
                        meta: {
                            title: 'Fintech Application V2',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/PhoneFintechAppV2/index.vue")
                    },
                    {
                        path: "/layout/Financials/PhoneFintechAppV3",
                        name: "PhoneFintechAppV3",
                        meta: {
                            title: 'Fintech Application V3',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/PhoneFintechAppV3/index.vue")
                    },
                    {
                        path: "/layout/Financials/PhoneFintechAppV4",
                        name: "PhoneFintechAppV4",
                        meta: {
                            title: 'Fintech Application V4',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/PhoneFintechAppV4/index.vue")
                    },
                    {
                        path: "/layout/Financials/PhoneFintechApp",
                        name: "PhoneFintechApp",
                        meta: {
                            title: 'Fintech Application V1',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/FintechApplicationPhone/index.vue")
                    },
                    {
                        path: "/layout/Financials/AssociatedNo",
                        name: "AssociatedNo",
                        meta: {
                            title: 'Associated Numbers',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/Associatednumber/index.vue")
                    }, {
                        path: "/layout/Financials/TopupRecords",
                        name: "TopupRecords",
                        meta: {
                            title: 'Topup Records',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/TopupRecords/index.vue")
                    }, {
                        path: "/layout/Financials/PhoneScore",
                        name: "PhoneScore",
                        meta: {
                            title: 'Phone Number Activity',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/PhoneScore/index.vue")
                    }, {
                        path: "/layout/Financials/SocialMedia",
                        name: "SocialMedia",
                        meta: {
                            title: 'WhatsApp Detection',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/SocialMedia/index.vue")
                    }, {
                        path: "/layout/Financials/PhoneAge",
                        name: "PhoneAge",
                        meta: {
                            title: 'Phone Age',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/PhoneAge/index.vue")
                    }, {
                        path: "/layout/Financials/Carrier",
                        name: "Carrier",
                        meta: {
                            title: 'Carrier',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Phone']   //面包屑导航
                        },
                        component: () => import("../view/Financials/Carrier/index.vue")
                    }, {
                        path: "/layout/Financials/KtpFintechApp",
                        name: "KtpFintechApp",
                        meta: {
                            title: 'Fintech Application V1',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/FintechApplicationKtp/index.vue")
                    }, {
                        path: "/layout/Financials/BlackList",
                        name: "Blacklist",
                        meta: {
                            title: ' P2P Blacklist',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/BlackList/index.vue")
                    },
                    {
                        path: "/layout/Financials/BlackList2",
                        name: "Blacklist2",
                        meta: {
                            title: ' P2P Blacklist',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/BlackList2/index.vue")
                    },
                    {
                        path: "/layout/Financials/FinNpwp",
                        name: "FinNpwp",
                        meta: {
                            title: 'BPJS Healthcare Detailed',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/NPWP/index.vue")
                    }, {
                        path: "/layout/Financials/KtpFintechAppV2",
                        name: "KtpFintechAppV2",
                        meta: {
                            title: 'Fintech Application V2',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/KtpFintechAppV2/index.vue")
                    }, {
                        path: "/layout/Financials/KtpFintechAppV3",
                        name: "KtpFintechAppV3",
                        meta: {
                            title: 'Fintech Application V3',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/KtpFintechAppV3/index.vue")
                    }, {
                        path: "/layout/Financials/KtpFintechAppV4",
                        name: "KtpFintechAppV4",
                        meta: {
                            title: 'Fintech Application V4',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'KTP']   //面包屑导航
                        },
                        component: () => import("../view/Financials/KtpFintechAppV4/index.vue")
                    },
                    {
                        path: "/layout/Financials/CreditScore",
                        name: "CreditScore",
                        meta: {
                            title: 'Credit Score',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Credit']   //面包屑导航
                        },
                        component: () => import("../view/Financials/CreditScore/index.vue")
                    }, {
                        path: "/layout/Financials/FraudScore",
                        name: "FraudScore",
                        meta: {
                            title: 'Fraud Score',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Credit']   //面包屑导航
                        },
                        component: () => import("../view/Financials/FraudScore/index.vue")
                    }, {
                        path: "/layout/Financials/SalaryIndex",
                        name: "SalaryIndex",
                        meta: {
                            title: 'Salary Index',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Credit']   //面包屑导航
                        },
                        component: () => import("../view/Financials/SalaryIndex/index.vue")
                    }
                    , {
                        path: "/layout/Financials/SanctionsList",
                        name: "SanctionsList",
                        meta: {
                            title: 'Sanctions List Lookup',
                            apiActiveMenu: '/layout/Financials', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Financials', 'Credit']   //面包屑导航
                        },
                        component: () => import("../view/Financials/SanctionsList/index.vue")
                    }
                ]
            },
            {
                path: "/layout/Report",
                names: "Report",
                component: () => import("../view/Report/index.vue"),
                meta: {
                    quanxian: true,
                    title: 'Report',
                    apiActiveMenu: '/layout/Report',//高亮
                    icon: "searchicon",
                },
                children: [

                    {
                        path: "/layout/Report/CreditReport",
                        name: "CreditReport",
                        meta: {
                            title: 'Credit Report',
                            icon: "redbag",
                            apiActiveMenu: '/layout/Report', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Report', 'Credit Report']   //面包屑导航
                        },
                        component: () => import("../view/Report/CreditReport/index.vue")
                    }
                ]
            }, {
                path: "/layout/Setting",
                names: "Setting",
                component: () => import("../view/Setting/index.vue"),
                meta: {
                    title: 'Setting',
                    apiActiveMenu: '/layout/Setting',//高亮
                    icon: "gear",
                },
                children: [
                    {
                        path: "/layout/Setting/Users",
                        name: "Users",
                        meta: {
                            title: 'Users',
                            apiActiveMenu: '/layout/Setting', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Setting', 'Users setting']   //面包屑导航
                        },
                        component: () => import("../view/Setting/Users/index.vue")
                    }, {
                        path: "/layout/Setting/SubUserSet",
                        name: "SubUserSet",
                        meta: {
                            title: 'SubUser',
                            apiActiveMenu: '/layout/Setting', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Setting', 'SubUser']   //面包屑导航
                        },
                        component: () => import("../view/Setting/SubUser/index.vue")
                    }, {
                        path: "/layout/Setting/Company",
                        name: "CompanySet",
                        meta: {
                            title: 'Companys',
                            apiActiveMenu: '/layout/Setting', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Setting', 'Users setting']   //面包屑导航
                        },
                        component: () => import("../view/Setting/Company/index.vue")
                    }, {
                        path: "/layout/Setting/Roles",
                        name: "RoleSet",
                        meta: {
                            title: 'Roles',
                            apiActiveMenu: '/layout/Setting', // 主菜单 的 接口文档 高亮
                            breadcrumb: ['Setting', 'Users setting']   //面包屑导航
                        },
                        component: () => import("../view/Setting/Roles/index.vue")
                    },
                ]
            }
        ]

    }, {
        path: "/login",
        names: "login",
        meta: {
            title: "login"
        },
        component: () => import("../view/login/index.vue"),
    }, {
        path: "/404",
        names: "404",
        component: () => import('../view/error-page/404/index.vue'),
    },]
const router = new VueRouter({
    mode: "hash",
    routes
})
// 深层查找menuName 返回false代表没有路由权限 返回true代表有权限
function findInNestedObj(arr, prop, value) {
    if (arr == null || arr.length == 0 || arr == undefined) {
        return false;
    }
    for (const obj of arr) {
        if (obj[prop] === value) {
            return true
        }
        if (Array.isArray(obj.children) && findInNestedObj(obj.children, prop, value)) {
            return true;
        }
    }
    return false;
}
// 查找不为0的下标
function findVisibleIndex(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].visible === '1') {
            return i;
        } else if (arr[i].children && arr[i].children.length > 0) {
            const result = findVisibleIndex(arr[i].children);
            if (result !== null) {
                return result;
            }
        }
    }
    return null;
}
//防止二次弹窗
let isTokenExpired = false;
router.beforeEach(async (to, from, next) => {
    //获取登录状态
    let tokenRaw = localStorage.getItem('token');
    let isLogin = tokenRaw ? JSON.parse(tokenRaw) : null;
    const currentUTCString = new Date().toISOString();
    if (to.path != "/login") {
       //在登录页外进行token过期拦截
        if (isLogin && currentUTCString > isLogin.expire) {
            if (!isTokenExpired) {
                isTokenExpired = true;
                MessageBox.alert("Token is expired，Please log in again", "prompt", {
                    confirmButtonText: "Yes",
                    showClose: false,
                    callback: () => {
                        isTokenExpired = false;
                        // 跳转到登录页面
                        return next('/login');
                    },
                });
            }
        }
    }


    let mymenu = JSON.parse(localStorage.getItem("mymenu"))
    //后续如果需要404页面线用if判断to.path是否为404的路径包裹下面的判断代码就好

    if (to.path == '/layout/Identity/test') {
        next();
        return
    }

    // 如果用户未登录且访问的不是登录页，则重定向至登录页
    if (!isLogin && to.path !== '/login') {
        return next('/login');
    }
    // 如果用户已登录但没有路由表，且访问的不是登录页，则重定向至登录页
    if (isLogin && !mymenu && to.path !== '/login') {
        return next('/login');
    }
    // 如果有路由表则根据路由表和点击的一级路由进行重定向
    if (isLogin && mymenu && to.path !== '/login') {
        const onemenuName = to.path.split('/')[2];
        if (onemenuName && !to.path.split('/')[3]) {
            let index = mymenu.findIndex(item => item.menuName == onemenuName)
            if (index != -1) {
                if (mymenu[index].children) {
                    // 二级重定向 根据数据的visible查看是否隐藏 如果隐藏则继续往下一位
                    let Myredirect = mymenu[index].children[0].children[findVisibleIndex(mymenu[index].children[0].children)]
                    let redirectSrc = `/layout/${onemenuName}/${Myredirect.menuName}`
                    return next(redirectSrc);
                }
            }
        }
    }
    // 路由拦截后
    if (to.path !== '/login' && !findInNestedObj(mymenu, 'menuName', to.name)) {
        next(false)
        // 阻止
        Message.warning("You do not have permission");
        // 清空缓存 可能被拦截后会重新登录 后续再设计这里  http://localhost:8080/#/
        // localStorage.clear()
        next('/login');
    } else {
        // 修改浏览器标题
        to.meta.title && (document.title = `iziData - ` + to.meta.title);
        // 以上条件都未触发时，默认放行
        next();
    }

})




export default router