<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

};

</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
  list-style: none;
  font-family: "PingFang SC", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji" !important;
}
@media not all and (min--moz-device-pixel-ratio: 2),
  not all and (-webkit-min-device-pixel-ratio: 2),
  not all and (min-device-pixel-ratio: 2),
  not all and (min-resolution: 192dpi),
  not all and (min-resolution: 2dppx) {
  .iitem-title {
    font-weight: 700 !important;
  }
}
#app {
  width: 100vw;
  height: 100vh;
  .el-form-item.is-error .el-input__inner {
    box-shadow: inset 0px 0px 3px 1px #f67676;
  }
  .el-input__inner{
    line-height: 1px;
  }
}
#notReplicable {
  cursor: pointer;
  user-select: none;
}
</style>
