import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '../node_modules/element-ui/lib/locale/lang/en' //英文)
// 图片懒加载
import VueLazyload from 'vue-lazyload'

// 路由配置
import router from './router'
import './icons'
// 修改head
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false


Vue.use(VueLazyload, {
  preLoad: 1, // 预加载的宽高比
  error: require('/public/liveness1.png'), // 图片加载失败时使用的图片
  loading: require('/public/liveness1.png'), // 图片加载时使用的loading动画
  attempt: 2 // 加载失败后最多尝试几次
})
new Vue({
  // el:"#app",z
  render: h => h(App),
  router: router,
  store: store
}).$mount('#app')
