
import Vue from 'vue'
import Vuex from 'vuex';

// 持久化插件
import createPersistedState from 'vuex-persistedstate'

//需要使用插件一次
Vue.use(Vuex);
//state:仓库存储数据
const Dashboard = {
    state: () => ({
        userdata: null, //用户数据
        activeName: null,//当前高亮
        Summary: null,//dashboard数据总结
        reqdata: null,//请求的曲线图数据
        apidata: null,//请求的扇形图数据
        monthbilldata: null,//请求的月账单数据
        SixBill: null,// 六个月汇总账单
        bilDetailsData: null,//单个月份数据
        balance: null,//当前余额数据
        Recharge: null//充值明细
    }),
    mutations: {
        SET_USERDATA(state, data) {
            state.userdata = data
        },
        SET_ACTIVE(state, data) {
            state.activeName = data
        },
        SET_SUMMARY(state, data) {
            state.Summary = data
        },
        SET_REQDATA(state, data) {
            state.reqdata = data
        },
        SET_APIDATA(state, data) {
            state.apidata = data
        },
        SET_MONTHBILLDATA(state, data) {
            state.monthbilldata = data
        },
        SET_SIXBILL(state, data) {
            state.SixBill = data
        },
        SET_BILDETAILSDATA(state, data) {
            state.bilDetailsData = data
        },
        SET_BALANCE(state, data) {
            state.balance = data
        },
        SET_RECARGE(state, data) {
            state.Recharge = data
        }

    },
    getters: {//计算属性
        activeName: state => state.activeName
    },

    actions: { //异步操作

    },
}
//mutations:修改state的唯一手段

//action:处理action,书写自己的业务逻辑，可以处理异步

//getters:理解为计算属性，用于简化仓库数据，让组件获取仓库数组更加方便

//对外暴露Store类的一个实例
export default new Vuex.Store({
    modules: {
        Dashboard: Dashboard,
        // 更多的模块...
    },
    plugins: [createPersistedState()]
})
